@tailwind base;
@tailwind components;
@tailwind utilities;

// BRAND COLORS - see tailwind.config.js
$color-brand--3: #f44dac;
$color-brand--5: #410b44;
$color-brand--6: #2a0233;
$color-brand--4: #e61883;
$color-brand--2: #fff3fa;

.input {
  @apply mt-2 w-full rounded-lg;
  @apply border-gray-300 py-4 px-6 text-lg font-bold dark:border-white/10 dark:bg-brand--5;

  &:disabled {
    @apply border border-gray-100 font-medium dark:border-brand--5 dark:bg-brand--6;
  }

  &--error {
    @apply border-red-600;
  }
}

.CodeMirror,
.editor-preview {
  @apply bg-white dark:bg-brand--5;
  @apply text-black dark:text-white;
  @apply rounded-lg border-gray-300 dark:border-white/10;
  @apply mb-5;
}
.CodeMirror-wrap {
  @apply dark:border-white/10 #{!important};
}

.editor-preview {
  @apply prose dark:prose-invert;
}

.CodeMirror-cursor {
  @apply border-black dark:border-white;
}

.editor-toolbar {
  opacity: 1 !important;
  @apply border-gray-300 dark:border-white/10;
  @apply mt-4;

  i.separator {
    @apply border-l-0 #{!important};
    @apply border-gray-300 dark:border-white/10;
  }

  a {
    @apply transition;
    @apply text-white #{!important};
    @apply dark:text-brand--3/50 #{!important};

    &:hover {
      @apply bg-transparent #{!important};
      @apply text-brand--3 #{!important};
    }
  }

  &.disabled-for-preview {
    a {
      @apply bg-transparent #{!important};
    }

    a:not(.no-disable) {
      @apply opacity-50;
    }
  }
}

.box {
  &--money-bg {
    background-repeat: no-repeat;
    background-image: url('/money-background.png');
    @media (min-width: 768px) {
      background-size: 70%;
      background-position: center right;
    }
    @media (max-width: 767px) {
      background-size: 150%;
      background-position: -125px 0;
    }
    @media (max-width: 460px) {
      background-size: 200%;
      background-position: -150px 0px;
    }
  }
}

body,
.overflow-y-scroll {
  -webkit-overflow-scrolling: touch;
}
